import React from 'react';
import { Button, Form, Input, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
// import AppsNavigation from './AppsNavigation';
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR
	// THEME_TYPE_LITE
} from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
	const { navStyle } = useSelector(({ settings }) => settings);
	const history = useHistory();
	const pathname = useSelector(({ common }) => common.pathname);

	const getNoHeaderClass = (navStyle) => {
		if (
			navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
		) {
			return 'gx-no-header-notifications';
		}
		return '';
	};

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split('/')[1];
	const onFinish = (values) => {
		// console.log(values);
		history.push('/track-shipment', { state: values });
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	return (
		<>
			<SidebarLogo
				sidebarCollapsed={sidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>
			<div className="gx-sidebar-content">
				<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
					<UserProfile />
					{/* <AppsNavigation /> */}
					<Form
						initialValues={{ remember: true }}
						name="basic"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className="gx-signin-form flex items-center gx-form-row0"
					>
						<Button type="text" htmlType="submit" className="m-0 p-0">
							<ul className="gx-app-nav">
								<li>
									<i className="icon icon-search-new" />
								</li>
							</ul>
						</Button>
						{/* <Menu
							defaultOpenKeys={[defaultOpenKeys]}
							selectedKeys={[selectedKeys]}
							// theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
							theme="lite"
							mode="inline"
						>
							<Menu.Item key="track-shipment"> */}

						<Form.Item
							rules={[{ required: true, message: 'Please input your AWB/Order Id!' }]}
							name="orderIdAwb"
						>
							<Input placeholder="AWB/OrderId" className="sidebar__trackshipment" />
						</Form.Item>
					</Form>

					{/* </Menu.Item> */}
					{/* </Menu> */}
				</div>
				<CustomScrollbars className="gx-layout-sider-scrollbar">
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						// theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
						theme="lite"
						mode="inline"
					>
						<Menu.Item key="dashboard">
							<Link to="/dashboard">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.dashboard" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="book-orders">
							<Link to="/book-orders">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.book-orders" />
								</span>
							</Link>
						</Menu.Item>

						<Menu.Item key="my-orders">
							<Link to="/my-orders">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.my-orders" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="my-addresses">
							<Link to="/my-address">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.my-addresses" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="bulk-upload">
							<Link to="/bulk-upload">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.bulk-upload" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="coreyo-wallet">
							<Link to="/coreyo-wallet">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.coreyo-wallet" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="privacy-policy">
							<Link to="/privacy-policy">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.privacy-policy" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="terms-of-services">
							<Link to="/terms-of-services">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.terms-of-services" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="packaging-guides">
							<Link to="/packaging-guides">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.packaging-guides" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="banned-items">
							<Link to="/banned-items">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.banned-items" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="contact-us">
							<Link to="/contact-us">
								<i className="icon icon-widgets" />
								<span>
									<IntlMessages id="sidebar.contact-us" />
								</span>
							</Link>
						</Menu.Item>
					</Menu>
				</CustomScrollbars>
			</div>
		</>
	);
};

export default React.memo(SidebarContent);
