import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { httpClient } from '../../../util/Api';

export const useProvideAuth = () => {
	const [authUser, setAuthUser] = useState(null);
	const [error, setError] = useState('');
	const [isLoadingUser, setLoadingUser] = useState(true);
	const history = useHistory();
	const [isLoading, setLoading] = useState(false);

	const fetchStart = () => {
		setLoading(true);
		setError('');
	};

	const fetchSuccess = () => {
		setLoading(false);
		setError('');
	};

	const fetchError = (error) => {
		setLoading(false);
		setError(error);
	};

	const userLogin = (user, callbackFun) => {
		fetchStart();
		httpClient
			.post('/reseller/login', user)
			.then(({ data }) => {
				console.log(user, data, 'user', 'data');
				if (data.data) {
					fetchSuccess();
					httpClient.defaults.headers.common['authorization'] = data.data.user.token;
					localStorage.setItem(
						'token',
						data.data.user.token,
						httpClient.defaults.headers.common['authorization']
					);
					history.push('/dashboard');
					getAuthUser();
					if (callbackFun) callbackFun();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error.message);
				httpClient.defaults.headers.common['authorization'] = '';
			});
	};

	const userSignup = (user, callbackFun) => {
		fetchStart();
		httpClient
			.post('user/register', user)
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					localStorage.setItem('token', data.data.token);
					httpClient.defaults.headers.common['authorization'] = data.data.token;
					getAuthUser();
					if (callbackFun) callbackFun();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error.message);
			});
	};

	const sendPasswordResetEmail = (email, callbackFun) => {
		fetchStart();

		setTimeout(() => {
			fetchSuccess();
			if (callbackFun) callbackFun();
		}, 300);
	};

	const confirmPasswordReset = (code, password, callbackFun) => {
		fetchStart();

		setTimeout(() => {
			fetchSuccess();
			if (callbackFun) callbackFun();
		}, 300);
	};

	const renderSocialMediaLogin = () => null;

	const userSignOut = (callbackFun) => {
		fetchStart();
		httpClient
			.get('user/logout')
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					httpClient.defaults.headers.common['authorization'] = '';
					localStorage.removeItem('token');
					setAuthUser(false);
					if (callbackFun) callbackFun();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error.message);
			});
	};

	const getAuthUser = () => {
		fetchStart();

		const token = localStorage.getItem('token');
		console.log(token, 'token');
		if (token) {
			httpClient.defaults.headers.common['authorization'] = token;
		}

		httpClient
			.get('user/auth/me')
			.then(({ data }) => {
				console.log(data, 'data');
				if (data.data.data) {
					setAuthUser(data.data.data);
				}
				setLoadingUser(false);
			})
			.catch(function (error) {
				localStorage.removeItem('token');
				httpClient.defaults.headers.common['authorization'] = '';
				setLoadingUser(false);
				fetchError(error.message);
			});
	};

	// Subscribe to user on mount
	// Because this sets state in the callback it will cause any ...
	// ... component that utilizes this hook to re-render with the ...
	// ... latest auth object.

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			httpClient.defaults.headers.common['authorization'] = token;
		} else {
			httpClient.defaults.headers.common['authorization'] = '';
			// history.push('/signin');
		}

		httpClient
			.get('user/auth/me')
			.then(({ data }) => {
				console.log(data, 'data');
				if (data.data.data) {
					setAuthUser(data.data.data);
				}
				setLoadingUser(false);
			})
			.catch(function () {
				localStorage.removeItem('token');
				httpClient.defaults.headers.common['authorization'] = '';
				setLoadingUser(false);
			});
	}, []);

	// Return the user object and auth methods
	return {
		isLoadingUser,
		isLoading,
		authUser,
		error,
		setError,
		setAuthUser,
		getAuthUser,
		userLogin,
		userSignup,
		userSignOut,
		renderSocialMediaLogin,
		sendPasswordResetEmail,
		confirmPasswordReset
	};
};
