import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Common from './Common';

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		settings: Settings,
		common: Common
	});

export default createRootReducer;
