import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';

const App = ({ match }) => {
	return (
		<div className="gx-main-content-wrapper">
			<Switch>
				<Route
					path={`${match.url}dashboard`}
					component={asyncComponent((props) => import('../containers/Pages/Dashboard'))}
				/>
				<Route
					path={`${match.url}my-account`}
					component={asyncComponent((props) => import('../containers/Pages/MyAccount'))}
				/>
				<Route
					path={`${match.url}track-shipment`}
					component={asyncComponent(() => import('../containers/Pages/TrackShipment'))}
				/>
				<Route
					path={`${match.url}my-orders`}
					component={asyncComponent(() => import('../containers/Pages/MyOrders'))}
				/>
				<Route
					path={`${match.url}order-details/:id`}
					component={asyncComponent(() =>
						import('../containers/Pages/MyOrders/OrderDetails')
					)}
				/>
				<Route
					path={`${match.url}privacy-policy`}
					component={asyncComponent(() => import('../containers/Pages/PrivacyPolicy'))}
				/>
				<Route
					path={`${match.url}terms-of-services`}
					component={asyncComponent(() => import('../containers/Pages/TermsOfServices'))}
				/>
				<Route
					path={`${match.url}packaging-guides`}
					component={asyncComponent(() => import('../containers/Pages/PackagingGuides'))}
				/>
				<Route
					path={`${match.url}banned-items`}
					component={asyncComponent(() => import('../containers/Pages/BannedItems'))}
				/>
				<Route
					path={`${match.url}book-orders`}
					component={asyncComponent((props) => import('../containers/Pages/BookOrders'))}
				/>
				<Route
					path={`${match.url}my-address`}
					component={asyncComponent(() => import('../containers/Pages/MyAddress'))}
				/>
				<Route
					path={`${match.url}coreyo-wallet`}
					component={asyncComponent(() => import('../containers/Pages/CoreyoWallet'))}
				/>
				<Route
					path={`${match.url}bulk-upload`}
					component={asyncComponent(() => import('../containers/Pages/BulkUpload'))}
				/>
				<Route
					path={`${match.url}providers`}
					component={asyncComponent((props) =>
						import('../containers/Pages/BookOrders/ListProviders')
					)}
				/>{' '}
				<Route
					path={`${match.url}contact-us`}
					component={asyncComponent(() => import('../containers/Pages/ContactUs'))}
				/>
				<Route
					path={`${match.url}checkout`}
					component={asyncComponent((props) =>
						import('../containers/Pages/BookOrders/CheckoutPage')
					)}
				/>
			</Switch>
		</div>
	);
};

export default App;
